<template>
	<header>
		<div
			class="buttonsContainer headerSection"
			:class="{
				active: statusIndex
			}"
		>
			<div class="categoryHeader" @click="toggleIndex">
				<a class="index-mobile link-tab pa-2">
					{{ selectedCategoryName }}
				</a>
				<div
					class="bar-toggle-wrapper"
					:class="{ active: statusIndex }"
				>
					<div class="bar-toggle"></div>
					<div class="bar-toggle"></div>
				</div>
			</div>

			<Button
				:left="true"
				v-for="(category, i) in recipeCategories"
				:key="i"
				color="aili-pink"
				:inverted="true"
				:value="category.name"
				@click="e => onCategoryClick(category, e)"
				:active="selectedCategory === category.slug"
				:href="getUrl('ricette/' + category.slug)"
				class="button-mobile"
			/>
			<Search
				v-if="$vuetify.breakpoint.width >= 1024"
				class="search"
				color="aili-pink"
			/>
		</div>
	</header>
</template>

<script>
import Search from "@/components/ui/Search.vue";

export default {
	name: "CategoryHeaderRecipes",
	components: { Search },
	props: ["value", "term"],
	data() {
		return {
			menus: null,
			selectedCategory: null,
			selectedCategoryName: null,
			statusIndex: false
		};
	},
	mounted() {
		this.selectedCategory = this.$route.params.category
			? this.$route.params.category
			: this.$route.params.tag
			? "tags"
			: "";
		this.updateSelectedCategoryName();
	},
	methods: {
		toggleIndex() {
			this.statusIndex = !this.statusIndex;
		},
		searchResults(results) {
			this.$emit("getResults", results);
		},
		onCategoryClick(category, e) {
			e.preventDefault();
			this.statusIndex = false;
			this.selectedCategory = category.slug;
			this.updateSelectedCategoryName();
			this.$emit("onChange", category);
			if (!category.slug) {
				this.goTo("Recipes");
			} else {
				this.goTo("Recipes", { category: category.slug });
			}
		},
		updateSelectedCategoryName() {
			let selectedCategoryName = this.recipeCategories.filter(
				category => {
					return category.slug === this.selectedCategory;
				}
			);
			this.selectedCategoryName = selectedCategoryName[0].name;
		}
	}
};
</script>

<style lang="scss" scoped>
.button-mobile {
	@media (max-width: $tablet-l) {
		width: 100%;
		background-color: transparent !important;
		color: #fff;
		border-color: transparent !important;
	}
}
a {
	text-decoration: none;
}
header {
	color: $aili-white;
}
.categoryHeader {
	display: none;
	@media (max-width: $tablet-l) {
		display: flex;
		justify-content: space-between;
	}
}

.search {
	position: absolute;
	right: 0;
	top: $spacing-0;
}

.filters {
	padding: $spacing-0;
}

.filters a {
	margin-right: 15px;
}
.buttonsContainer {
	position: relative;
	padding: $spacing-0 0;
}

#app .link-tab.index-mobile {
	display: none;
}

.bar-toggle-wrapper {
	height: 30px;
	width: 30px;
	margin-top: 10px;
	margin-right: 4px;
	position: relative;
	float: left;
	display: none;
	.bar-toggle {
		height: 2px;
		width: 14px;
		background: #fff;
		display: block;
		opacity: 1;
		margin-top: 0;
		transition: all 0.15s linear;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.bar-toggle:nth-child(2) {
		transform: rotate(90deg) translate(0, 6px);
		transform-origin: 50% 0;
	}
	@media (max-width: $tablet-l) {
		display: block;
	}
}

.bar-toggle-wrapper.active {
	.bar-toggle:nth-child(2) {
		transform: rotate(0) translate(-50%, -50%);
	}
}

@media (max-width: $tablet-l) {
	header {
		background: #ff7cb9;
		position: sticky;
		top: 70px;
		z-index: 15;
	}

	.buttonsContainer {
		.search {
			display: none;
			opacity: 0;
			transition: all 0.3s;
			bottom: 4px;
			top: auto;
			background-color: transparent !important;
			width: 90% !important;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	.buttonsContainer.active {
		.search {
			opacity: 1;
			display: block;
		}
		.close-icon {
			display: block;
		}
	}
	.headerSection {
		position: absolute;
		display: flex;
		flex-direction: column;
		transform: translateX(-100%);
		position: relative;
		transform: none;
		display: inline-block;
		flex-direction: unset;
		justify-content: center;
		width: 100%;
		max-height: 46px;
		padding: 0px 17px;
		transition: max-height 500ms;
		overflow: hidden;
		&.active {
			max-height: 1000px;
		}
	}
	.label {
		text-transform: uppercase;
		transition: 0.4s all;
		text-transform: none;
		font-size: $font-size-20;
		float: none;
		clear: both;
		width: 100%;
		display: inline-block;
	}

	.headerSection.active {
		max-height: 1000px;
	}

	#app .link-tab {
		border-radius: 10px 0 0 10px;
		transition: transform 1s ease;
		color: $aili-white !important;
		writing-mode: vertical-rl;
		transform: rotate(180deg);
		background: transparent !important;
		line-height: 35px;
		transform: none;
		writing-mode: unset;
		font-size: $font-size-20 !important;
	}

	#app .link-tab.index-mobile {
		font-size: 16px !important;
		text-transform: uppercase;
		display: block;
	}
}
</style>
